.post-submitted-card input,
.post-submitted-card select {
  padding: 0.375rem;
  border-radius: 3px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.post-submitted-card label {
  margin-bottom: 0.375rem;
  color: #333;
}

.post-submitted-card {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.post-submitted-card h2 {
  text-align: center;
  color: #333;
}
.post-submitted-card input,
.post-submitted-card select {
  min-width: 90%;
}

.submit-details h3 > a {
  text-decoration: none;
  color: #cca8e9;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.error {
  font-weight: bold;
  color: red;
  margin-top: 8px;
}