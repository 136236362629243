.row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.gap-0 > * {
  padding: 0;
}

.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 > * {
  padding: 10px;
}

.gap-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.gap-2 > * {
  padding: 20px;
}

.gap-2 {
  margin-left: -20px;
  margin-right: -20px;
}

.gap-3 > * {
  padding: 30px;
}

.gap-3 {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 580px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.popular-header-container {
  text-align: left !important;
  width: 100%;
  font-weight: bold;
  font-size: 1.5rem;
}

.popular-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0.75rem;
}

.popular-product-card {
  padding: 10px;
  border: grey 2px solid;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.popular-product-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 0.75rem;
}

.popular-product-title,
.popular-product-price {
  font-weight: bold;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9edc9;
  flex-direction: column;
  padding: 0.75rem 0.375rem;
}

.banner-header {
  font-size: 2rem;
  padding: 1.5rem;
}

.banner-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.banner-single-card {
  display: flex;
  flex: 1 1 0%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0.75rem;
  min-width: 300px;
  max-width: 500px;
}

.banner-card-title {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 0.75rem;
  font-weight: bold;
}

.banner-question {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1.875rem;
}

.home-image {
  margin: 0.75rem;
  width: 95vw;
  position: relative;
}

.banner-image-div {
  border-radius: 32px;
  padding: 0.75rem;
  transition: all 0.3s ease 0s;
}
.banner-image-div:hover {
  border: 1px solid grey;
  transform: scale(1.005);
}

.banner-image {
  border-radius: 20px;
  width: 100%;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
}

.home-video {
  max-width: fit-content;
}

.home-slider {
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  margin: 0.75rem;
}