.main-nav-container, .navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.nav-search {
  display: flex;
  align-items: center;
}

.search-bar {
  padding: 0.375rem;
  border-radius: 3px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.navbar {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  background-color: #747264;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  z-index: 6;
  display: block;
}

.main-nav-container {
  padding: 0.75rem;
  padding-top: 0;
  z-index: 5;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  justify-items: center;
}

.sticky-navbar {
  left: 0;
  position: fixed;
  top: 0;
  background-color: #747264;
  width: 100%;
  z-index: 5;
}

.logo {
  cursor: pointer;
}

.navbar li {
  list-style: none;
  display: inline-block;
  margin: 0.75rem;
}

.navbar li a {
  text-decoration: none;
  margin: 0.75rem;
  transition: all 0.3s ease 0s;
  color: #eeeeee;
}

.navbar li a :hover {
  opacity: 0.8;
}

/* see edits */
.search-bar {
  height: 4vh;
  border: none;
  outline: none;
  box-shadow: none;
  width: 100%;
  min-width: 130px;
}

.navbar-menu {
  display: none;
}

@media (min-width: 0) {
  .navbar-links {
    display: none;
  }
  .navbar-menu {
    display: inline-block;
    width: 40px;
  }
}
@media (min-width: 580px) {
  .navbar-links {
    display: flex;
    align-items: center;
  }
}
.sidebar {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #fff;
  padding: 0.75rem;
  border: 1px solid #ccc;
  margin-top: 0.75rem;
  font-size: 1.5rem;
  color: #222831;
  bottom: 0px;
  top: 58px;
  z-index: 4;
}

.sidebar-content {
  height: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}

.sidebar a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.sidebar a:hover {
  color: #000;
}

.nav-search {
  padding: 0;
}

.sign-in-div {
  margin-left: 7px;
  cursor: pointer;
}

.cart-div {
  cursor: pointer;
}

.search-svg {
  margin-left: 0.375rem;
}

.cancel-search-svg {
  width: 25px;
}

.sign-in-icon {
  margin-left: 7px;
}

.search-container {
  display: grid;
  background-color: #fff;
  grid-template-columns: 38px auto 30px;
  align-items: center;
  width: 60%;
  max-width: 600px;
  margin: 0 auto;
  line-height: 35px;
  border-radius: 6px;
  border: 2px solid #ccc;
}

.search-container:focus-within {
  border: 2px solid blue;
}

.category-navebar {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1.5px dashed black;
}

.navbar-logo {
  width: 80px;
  margin-top: 4px;
}

.right-navbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-navbar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.navbar-ribbon {
  background-color: #c9c8c8;
  height: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  align-items: center;
  font-size: small;
}

.navbar-social {
  display: inline-block;
  margin: 0 0.375rem;
}

.navbar-icons {
  margin: 0 0.375rem;
}

.navbar-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 20px;
}

.mobile-nav-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 62px;
}

.navbar-links-mobile > li {
  margin: 0 0 0 5px;
  color: #353131;
}

.bars {
  color: #cda750;
  font-size: x-large;
  margin-left: 33px;
}