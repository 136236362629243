.inquiry-container {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 130px;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.input-field,
.textarea-field {
  padding: 0.375rem;
  border-radius: 3px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.gap-0 > * {
  padding: 0;
}

.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 > * {
  padding: 10px;
}

.gap-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.gap-2 > * {
  padding: 20px;
}

.gap-2 {
  margin-left: -20px;
  margin-right: -20px;
}

.gap-3 > * {
  padding: 30px;
}

.gap-3 {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 580px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.inquiry-container form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.input-field,
.textarea-field {
  width: 100%;
  box-sizing: border-box;
}