/* Sidebar.css */
.profile-main-container {
  margin-top: 180px;
}

.profile-sidebar {
  position: fixed;
  z-index: 100;
}

.profile-li {
  padding: 0.75rem;
  margin: 1.125rem 2.25rem;
  font-weight: bold;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  width: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.profile-active {
  border: black solid 2px;
  cursor: default;
  opacity: 1;
}

.no-orders-img {
  max-width: 30vw;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translateY(55%);
}

.my-orders-container {
  width: 100%;
}

.one-order-container {
  padding: 0.75rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid grey;
}

.order-details {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 3fr 1fr;
  column-gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.order-date {
  text-align: center;
  font-weight: bold;
  font-size: large;
}

.one-order-container:nth-child(odd) {
  background-color: #dbe6e9;
}

.one-order-container:nth-child(even) {
  background-color: #f6f7f6;
}

.orders-images {
  display: inline-block;
  height: 120px;
  width: 120px;
  margin-bottom: 0.75rem;
}

.mobile-ul {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 2fr 2fr;
  width: 100%;
  margin-bottom: 20px;
  row-gap: 20px;
  column-gap: 20px;
  padding: 15px;
}

.mobile-ul > * {
  width: 100%;
  margin: 0;
}

.mobile-sidebar {
  display: flex;
}

.my-orders-section {
  position: relative;
  width: 65vw;
  margin-bottom: 0.75rem;
}

.my-orders-section,
.account-details-section,
.change-password {
  margin-left: 29%;
}

.zero-left {
  margin-left: 0;
  width: 100%;
}