.about-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 0;
  padding: 0 0.75rem;
  padding-top: 5rem;
  box-sizing: border-box;
  background-color: #fff;
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  margin-top: 100px;
}

.about-container img {
  max-width: 45%;
  height: auto;
  margin-right: 0.75rem;
}

.about-container > div {
  flex-grow: 1;
}

.about-info h1 {
  text-align: center;
  font-weight: bold;
}

.about-info {
  line-height: 2;
}

.about-info h2 {
  font-weight: bold;
}

@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
  }
  .about-container img {
    margin-right: 0;
    margin-bottom: 0.75rem;
    max-width: 100%;
  }
  .about-info {
    line-height: normal;
  }
}
.leaflet-container > .leaflet-pane {
  z-index: 1;
}

.leaflet-control {
  display: none;
}

.contact-details {
  width: 98%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0.75rem;
  margin-bottom: 3.75rem;
}

.map-container {
  width: 100%;
  margin: 125px auto;
  margin-bottom: 0;
}

.contact-title {
  font-weight: bold;
  text-align: center;
  font-size: 2.5rem;
  margin: 1.5rem;
}

.office {
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid grey;
}

.contact-li {
  text-align: center;
}