.checkout-allcart, .checkout-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 0;
  padding: 0 0.75rem;
  padding-top: 5rem;
  box-sizing: border-box;
  background-color: #fff;
}

.checkout-discount-input {
  padding: 0.375rem;
  border-radius: 3px;
  font-size: 1rem;
  border: 1px solid #ccc;
  margin-bottom: 0.75rem;
}

.checkout-form {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.gap-0 > * {
  padding: 0;
}

.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 > * {
  padding: 10px;
}

.gap-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.gap-2 > * {
  padding: 20px;
}

.gap-2 {
  margin-left: -20px;
  margin-right: -20px;
}

.gap-3 > * {
  padding: 30px;
}

.gap-3 {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 580px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.checkout {
  grid-template-columns: 2fr 1fr;
}

.checkout-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: initial;
  padding-top: 65px;
}

.checkout-address {
  border-bottom: 1px solid grey;
  width: 100%;
  margin: 0.75rem;
  padding: 0.75rem;
}
.checkout-address h2 {
  font-size: large;
  font-weight: bold;
  display: inline;
  margin-left: 0.75rem;
}

.ceckout-element {
  border: 1px solid grey;
  border-radius: 10px;
  margin: 0.75rem;
}

.ceckout-element-info {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.checkout-paymob {
  background-color: #dedede;
  color: #707070;
  padding: 0.75rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.checkout-paymob p {
  width: 65%;
}

.checkout-svg {
  max-width: 20rem;
  padding: 0.75rem;
  width: 100%;
}

.checkout-payment-info {
  transition: all 0.3s ease 0s;
}

.checkout-paymob {
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease-out;
  padding: 0;
}

.show {
  opacity: 1;
  padding: 0.75rem;
  max-height: 1000px;
  transition: all 0.3s ease-out;
}

.checkout-allcart {
  background-color: #f5f5f5;
  padding: 1.5rem;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.checkout-summary h1 {
  font-weight: bold;
  font-size: large;
}
.checkout-summary h2 {
  font-size: large;
}

.checkout-cart-components {
  padding-top: 50px;
}

.checkout-product-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 0.75rem;
  align-items: center;
}

.checkout-product-quantity {
  position: absolute;
  top: -12px;
  left: 58px;
  background-color: #666;
  border-radius: 50%;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-product-img {
  position: relative;
  background-color: #fff;
  width: 75px;
  height: 65px;
  display: flex;
  align-items: center;
  border: 1px solid grey;
  padding: 0.375rem;
  border-radius: 6.6666666667px;
  margin-bottom: 0.75rem;
}

.checkout-form {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkout-discount-input {
  margin-bottom: 0;
  width: 75%;
}

.checkout-order-discount {
  display: flex;
  justify-content: space-between;
}

.shipping-address-info {
  margin-left: 20px;
}

.discount-message {
  margin-bottom: 0.75rem;
  color: red;
}

.green {
  color: green;
}

.place-order {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}