.footer {
  background-color: #eeedeb;
  width: 100%;
  height: 235px;
  display: flex;
  justify-content: space-around;
  padding: 0.75rem;
}

.footer-logo {
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
  max-height: 100%;
}

.footer-contact {
  margin-top: 0.75rem;
}

.footer-social {
  display: flex;
  flex-direction: row;
  font-size: large;
  margin-top: 0.375rem;
}