.row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.gap-0 > * {
  padding: 0;
}

.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 > * {
  padding: 10px;
}

.gap-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.gap-2 > * {
  padding: 20px;
}

.gap-2 {
  margin-left: -20px;
  margin-right: -20px;
}

.gap-3 > * {
  padding: 30px;
}

.gap-3 {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 580px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.delete-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #cca8e9;
  line-height: 1;
  color: black;
  transition: 0.25s;
  padding: 0.75rem 1.5rem;
}
.delete-btn:hover, .delete-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}

.cart-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #4e564e;
  line-height: 1;
  color: black;
  transition: 0.25s;
  padding: 0.75rem 1.5rem;
}
.cart-btn:hover, .cart-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}

.draft-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #cca8e9;
  line-height: 1;
  color: black;
  transition: 0.25s;
  margin: 0.75rem;
}
.draft-btn:hover, .draft-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}

.submit-newsletter {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #cca8e9;
  line-height: 1;
  color: black;
  transition: 0.25s;
}
.submit-newsletter:hover, .submit-newsletter:focus {
  border-color: #c3bef0;
  color: #fff;
}

.search-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #cca8e9;
  line-height: 1;
  color: black;
  transition: 0.25s;
  padding: 1vh 1.5vh;
}
.search-btn:hover, .search-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}

.contact-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #d6dab9;
  line-height: 1;
  color: black;
  transition: 0.25s;
  margin: 0.75rem;
}
.contact-btn:hover, .contact-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}

.login-container .login-btn,
.login-container .submit-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #4caf50;
  line-height: 1;
  color: black;
  transition: 0.25s;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
}
.login-container .login-btn:hover, .login-container .login-btn:focus,
.login-container .submit-btn:hover,
.login-container .submit-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}
.login-container .login-btn:hover,
.login-container .submit-btn:hover {
  background-color: #45a049;
}

.form-container .login-btn,
.form-container .submit-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #4caf50;
  line-height: 1;
  color: black;
  transition: 0.25s;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
}
.form-container .login-btn:hover, .form-container .login-btn:focus,
.form-container .submit-btn:hover,
.form-container .submit-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}
.form-container .login-btn:hover,
.form-container .submit-btn:hover {
  background-color: #45a049;
}

.checkout-discount-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #1773b0;
  line-height: 1;
  color: black;
  transition: 0.25s;
  padding: 11.1px;
  background-color: #1773b0 !important;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
}
.checkout-discount-btn:hover, .checkout-discount-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}
.checkout-discount-btn:hover {
  background-color: #105989 !important;
}

.finish-order-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #4caf50;
  line-height: 1;
  color: black;
  transition: 0.25s;
  padding: 11px;
  background-color: #4caf50 !important;
  border-radius: 3px;
  color: #fff;
}
.finish-order-btn:hover, .finish-order-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}
.finish-order-btn:hover {
  background-color: #45a049 !important;
}

.submit-btn {
  width: 212px;
  height: 38px;
}

.register-btn {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #457fa0;
  line-height: 1;
  color: black;
  transition: 0.25s;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
  width: 100%;
  text-align: center;
}
.register-btn:hover, .register-btn:focus {
  border-color: #c3bef0;
  color: #fff;
}
.register-btn:hover {
  background-color: #455aa0;
}

.inquiry-container .submit-inquiry {
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 0;
  padding: 0.75rem;
  background-color: #4caf50;
  line-height: 1;
  color: black;
  transition: 0.25s;
  border-radius: 3px;
  color: #fff;
  margin: 10px 0;
}
.inquiry-container .submit-inquiry:hover, .inquiry-container .submit-inquiry:focus {
  border-color: #c3bef0;
  color: #fff;
}
.inquiry-container .submit-inquiry:hover {
  background-color: #45a049;
}

.fill:hover,
.fill:focus {
  box-shadow: inset 0 0 0 2em #c3bef0;
}

.pulse:hover,
.pulse:focus {
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #c3bef0;
  }
}
.close:hover,
.close:focus {
  box-shadow: inset -3.5em 0 0 0 #c3bef0, inset 3.5em 0 0 0 #c3bef0;
}

.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em #c3bef0;
  transform: translateY(-0.25em);
}

.up:hover,
.up:focus {
  box-shadow: inset 0 -3.25em 0 0 #c3bef0;
}

.sliding:hover,
.sliding:focus {
  box-shadow: inset 6.5em 0 0 0 red;
}

.offset {
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);
}
.offset:hover, .offset:focus {
  box-shadow: 0 0 0 0 #c3bef0, inset 6em 3.5em 0 0 #c3bef0;
}