.row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.gap-0 > * {
  padding: 0;
}

.gap-0 {
  margin-left: 0;
  margin-right: 0;
}

.gap-1 > * {
  padding: 10px;
}

.gap-1 {
  margin-left: -10px;
  margin-right: -10px;
}

.gap-2 > * {
  padding: 20px;
}

.gap-2 {
  margin-left: -20px;
  margin-right: -20px;
}

.gap-3 > * {
  padding: 30px;
}

.gap-3 {
  margin-left: -30px;
  margin-right: -30px;
}

@media (min-width: 0) {
  .col-1-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xs {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 580px) {
  .col-1-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-sm {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 720px) {
  .col-1-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-md {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 960px) {
  .col-1-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-lg {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .col-1-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 8.3333333333%;
  }
  .col-2-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 16.6666666667%;
  }
  .col-3-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 25%;
  }
  .col-4-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 33.3333333333%;
  }
  .col-5-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 41.6666666667%;
  }
  .col-6-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 50%;
  }
  .col-7-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 58.3333333333%;
  }
  .col-8-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 66.6666666667%;
  }
  .col-9-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 75%;
  }
  .col-10-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 83.3333333333%;
  }
  .col-11-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 91.6666666667%;
  }
  .col-12-xl {
    box-sizing: border-box;
    flex-grow: 0;
    width: 100%;
  }
}
.cards {
  border: 1px solid grey;
  background-color: white;
  min-height: 340px;
  position: relative;
  transition: 0.4s ease-out;
}
.cards:hover:before {
  opacity: 1;
}
.cards:hover .card-info {
  opacity: 1;
  transform: translateY(0%);
}
.cards:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.23);
  z-index: 2;
  transition: 0.5s;
  opacity: 0;
}

.img-container {
  position: relative;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.card-img {
  object-fit: cover;
  position: relative;
  width: 160px;
  height: 160px;
}

.card-info {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: white;
  opacity: 0;
  transform: translateX(30px);
  transition: 0.5s;
}

.card-title {
  margin: 0.75rem;
  font-size: 0.8rem;
  font-weight: normal;
}

.card-price {
  margin: 0.75rem;
  font-weight: bolder;
  font-size: 1.1rem;
  position: absolute;
  bottom: 15px;
}

.add-to-cart-hover {
  margin: 0.375rem;
  letter-spacing: 1px;
  position: absolute;
  word-break: break-word;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}